import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {IsAuthenticated} from './guards/isAuthenticated';
import {LoginComponent} from './login/login.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: 'mandanten',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    canActivate: [IsAuthenticated],
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'mandanten',
    canActivate: [IsAuthenticated],
    loadChildren: () => import('./mandanten/mandanten.page.module').then( m => m.MandantenPageModule)
  },
  {
    path: 'einstellungen',
    loadChildren: () => import('./einstellungen/einstellungen.module').then( m => m.EinstellungenPageModule)
  },
  {
    path: 'tools',
    canActivate: [IsAuthenticated],
    loadChildren: () => import('./tools/tools.page.module').then( m => m.ToolsPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [IsAuthenticated],
    loadChildren: () => import('./dashboard/dashboard.page.module').then( m => m.DashboardPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
