export interface IEnvironment {
    production: boolean;
    debug: boolean;
    appConfig: IAppConfig;
    azureAd: IAzureAdConfig;
    appTitle: string;
    loginRoute: string;
    version: string;
}

export interface IAppConfig {
    apiUrl: string;
    wsEndpoint: string;
    appInsightsInstrumentationKey: string;
}

export interface IAzureAdConfig {
    clientId: string,
    tenantId: string,
    resource: string,
    discoveryDocumentV1: string
}

export const azureAdminClientDev = <IAzureAdConfig>{
    clientId: "b45f1ceb-e0fc-4a02-8659-ca9857ea7df6", //application id
    tenantId: "29abe415-d035-4d18-9ccd-4f085365e073",
    resource: "1df93e2b-5dad-4c54-aa3f-20569ff4de51",
    discoveryDocumentV1: `https://login.microsoftonline.com/29abe415-d035-4d18-9ccd-4f085365e073/.well-known/openid-configuration`
};

export const azureAdminClientStag = <IAzureAdConfig>{
    clientId: "1a576965-f876-4b36-a27c-b5fb4df3bb47", //application id
    tenantId: "29abe415-d035-4d18-9ccd-4f085365e073",
    resource: "8ab20a21-5fa0-4d89-8392-125469e74cc1",
    discoveryDocumentV1: `https://login.microsoftonline.com/29abe415-d035-4d18-9ccd-4f085365e073/.well-known/openid-configuration`
};

export const azureAdminClientProd = <IAzureAdConfig>{
    clientId: "788c562d-a41e-460f-ad6a-af913dd5af0c", //application id
    tenantId: "29abe415-d035-4d18-9ccd-4f085365e073",
    resource: "77991b59-7577-4292-b4ec-bdf115bb872e",
    discoveryDocumentV1: `https://login.microsoftonline.com/29abe415-d035-4d18-9ccd-4f085365e073/.well-known/openid-configuration`
};

export const appConfigLocalHost = <IAppConfig>{
    apiUrl: "http://localhost:7000",
    wsEndpoint: "https://localhost:7000/api/websocket",
    appInsightsInstrumentationKey: null
};

export const appConfigDev = <IAppConfig>{
    apiUrl: "https://api-dev.orga-app.de",
    wsEndpoint: "https://api-dev.orga-app.de/api/websocket",
    appInsightsInstrumentationKey: null
};

export const appConfigStag = <IAppConfig>{
    apiUrl: "https://api-stag.orga-app.de",
    wsEndpoint: "https://api-stag.orga-app.de/api/websocket",
    appInsightsInstrumentationKey: null
};

export const appConfigProd = <IAppConfig>{
    apiUrl: "https://api.orga-app.de",
    wsEndpoint: "https://api.orga-app.de/api/websocket",
    appInsightsInstrumentationKey: null
};


