import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sopi-inner-item',
    templateUrl: './sopi-inner-item.component.html',
    styleUrls: ['./sopi-inner-item.component.scss'],
})
export class SopiInnerItemComponent implements OnInit {

    // VALUES
    @Input() topLeftValue: string = null;
    @Input() topRightValue: string = null;
    @Input() mainValue: string = null;
    @Input() subValue: string = null;
    @Input() bottomIconName: string = null;
    @Input() bottomLeftValue: string = null;
    @Input() bottomRightValue: string = null;
    @Input() bottomSubValue: string = null;
    @Input() bottom2IconName: string = null;
    @Input() bottom2LeftValue: string = null;
    @Input() bottom2RightValue: string = null;
    @Input() bottom2SubValue: string = null;

    @Input() iconIstOk = false;
    @Input() iconIstNichtOk = false;
    @Input() iconIstInBearbeitung = false;

    // DESIGN
    @Input() rowTopEnabled = true;
    @Input() rowSubEnabled = true;
    @Input() badgeEnabled = true;
    @Input() badgeValue: string = null;
    @Input() badgeStyleClass: string = null;
    @Input() rowBottomEnabled = true;
    @Input() rowBottomSubEnabled = true;
    @Input() rowBottom2SubEnabled = true;

    constructor() {
    }

    ngOnInit() {
    }

}
