// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {appConfigDev, azureAdminClientDev, IEnvironment} from './model';
import versions from '../_versions';

export const environment = <IEnvironment>{
    appTitle: "ORGA App Admin Client DEV",
    production: false,
    debug: true,
    appConfig: appConfigDev,
    azureAd: azureAdminClientDev,

    loginRoute: '/login',
    version: versions.versionLong
};
