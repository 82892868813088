import { Component, OnInit } from '@angular/core';

let instances = 0;

@Component({
  selector: 'sopi-scroll',
  templateUrl: './sopi-scroll.component.html',
  styleUrls: ['./sopi-scroll.component.scss']
})
export class SopiScrollComponent implements OnInit {
  isMobile = false;
  id: number;

  constructor() {
    this.id = ++instances;
  }

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 768;
    // console.log(w);
    return w < breakpoint;
  }

  ngOnInit() {
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }

}
