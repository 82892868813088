import {Injectable, Injector} from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class AllowOriginInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        let requestToForward = req.clone({
            setHeaders: {'Access-Control-Allow-Origin': '*'}
        });
        return next.handle(requestToForward);
    }
}
