import {Inject, Injectable, Optional} from '@angular/core';
import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface BildUploadResponse {
    bild_id?: string;
}

@Injectable()
export class ImageService {

    public defaultHeaders: Headers = new Headers();
    public defaultHttpHeaders = new HttpHeaders();
    public configuration: Configuration = new Configuration();
    protected basePath = '';

    constructor(protected httpClient: HttpClient,
                @Optional() @Inject(BASE_PATH) basePath: string) {
        if (basePath) {
            this.basePath = basePath;
        }
    }

    public getImage(urlWithoutBaseUrl: string, extraHttpRequestParams?: any): Observable<Blob> {
        // console.log('getImage: ', urlWithoutBaseUrl);
        return this.httpClient
            .get(this.basePath + urlWithoutBaseUrl, {
                // headers: headers,
                responseType: 'blob'
            });
    }

    public getLocalImage(urlWithoutBaseUrl: string, extraHttpRequestParams?: any): Observable<Blob> {
        return this.httpClient
            .get(urlWithoutBaseUrl, {
                // headers: headers,
                responseType: 'blob'
            });
    }

    public urltoFile(url, filename, mimeType) {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
        return (fetch(url)
                .then((res) => {
                    return res.arrayBuffer();
                })
                .then((buf) => {
                    return new File([buf], filename, {type: mimeType});
                })
        );
    }


    /* Folgendes stammt aus dem generierten Client von Swagger */

    // TODO: Hier muss noch die Umstellung von hhtp auf httpClient erfolgen
    /**
     * GetAufgabeFehlerBild (BC:VERANSTALTUNGEN)
     *
     * @param aufgabeId
     * @param bildId
     */
    public getAufgabeFehlerBildWithHttpInfo(aufgabeId: string, bildId: string, extraHttpRequestParams?: any): Observable<Response> {
        const path = this.basePath + '/api/query/veranstaltungen/aufgaben/${aufgabeId}/fehlerbild/${bildId}'
            .replace('${' + 'aufgabeId' + '}', String(aufgabeId))
            .replace('${' + 'bildId' + '}', String(bildId));

        // const queryParameters = new URLSearchParams('', new CustomQueryEncoderHelper());
        // const headers = new Headers(this.defaultHeaders.toJSON()); // https://github.com/angular/angular/issues/6845
        //
        // // verify required parameter 'aufgabeId' is not null or undefined
        // if (aufgabeId === null || aufgabeId === undefined) {
        //     throw new Error('Required parameter aufgabeId was null or undefined when calling getAufgabeFehlerBild.');
        // }
        // // verify required parameter 'bildId' is not null or undefined
        // if (bildId === null || bildId === undefined) {
        //     throw new Error('Required parameter bildId was null or undefined when calling getAufgabeFehlerBild.');
        // }
        //
        // // to determine the Accept header
        // const produces: string[] = [];
        //
        // // authentication (oauth2) required
        // // oauth required
        // if (this.configuration.accessToken) {
        //     const accessToken = typeof this.configuration.accessToken === 'function'
        //         ? this.configuration.accessToken()
        //         : this.configuration.accessToken;
        //     headers.set('Authorization', 'Bearer ' + accessToken);
        // }
        //
        //
        // // let requestOptions: RequestOptionsArgs = new RequestOptions({
        // //     method: RequestMethod.Get,
        // //     headers,
        // //     search: queryParameters,
        // //     withCredentials: this.configuration.withCredentials
        // // });
        // // https://github.com/swagger-api/swagger-codegen/issues/4037
        // if (extraHttpRequestParams) {
        //     requestOptions = (<any> Object).assign(requestOptions, extraHttpRequestParams);
        // }
        //
        // return this.httpClient.request<any>(path, requestOptions);


        if (aufgabeId === null || aufgabeId === undefined) {
            throw new Error('Required parameter aufgabeId was null or undefined when calling getAufgabeFehlerBild.');
        }
        if (bildId === null || bildId === undefined) {
            throw new Error('Required parameter bildId was null or undefined when calling getAufgabeFehlerBild.');
        }

        const headers = this.defaultHttpHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<any>(path,
            {
                headers,
                withCredentials: this.configuration.withCredentials,
            }
        );
    }


    /**
     * getPruefungsaufgabeBildWithHttpInfo (BC:GASTROCHECKS)
     *
     * @param aufgabeId
     * @param bildId
     */
    public getPruefungsaufgabeBildWithHttpInfo(veranstaltungId: string, counterId: string, pruefungId: string, aufgabeId: string, bildId: string, extraHttpRequestParams?: any): Observable<Response> {
        // const path = this.basePath + '/api/query/gastrochecks/veranstaltungen/${veranstaltungId}/pruefungen/${counterId}/aufgaben/${aufgabeId}/bilder/${bildId}'
        const path = this.basePath + '/api/query/gastrochecks/veranstaltungen/${veranstaltungId}/counter/${counterId}/pruefungen/${pruefungId}/aufgaben/${aufgabeId}/bilder/${bildId}'
            .replace('${' + 'veranstaltungId' + '}', String(veranstaltungId))
            .replace('${' + 'counterId' + '}', String(counterId))
            .replace('${' + 'pruefungId' + '}', String(pruefungId))
            .replace('${' + 'aufgabeId' + '}', String(aufgabeId))
            .replace('${' + 'bildId' + '}', String(bildId));

        return this.getBild(path, aufgabeId, bildId, extraHttpRequestParams);
    }


    /**
     * getVipPruefungsaufgabeBildWithHttpInfo (BC: VIPCHECKS)
     *
     * @param aufgabeId
     * @param bildId
     */
    public getVipPruefungsaufgabeBildWithHttpInfo(veranstaltungId: string, counterId: string, pruefungId: string, aufgabeId: string, bildId: string, extraHttpRequestParams?: any): Observable<Response> {
        const path = this.basePath + '/api/query/vipchecks/veranstaltungen/${veranstaltungId}/vipbereich/${counterId}/pruefungen/${pruefungId}/aufgaben/${aufgabeId}/bilder/${bildId}'
            .replace('${' + 'veranstaltungId' + '}', String(veranstaltungId))
            .replace('${' + 'counterId' + '}', String(counterId))
            .replace('${' + 'pruefungId' + '}', String(pruefungId))
            .replace('${' + 'aufgabeId' + '}', String(aufgabeId))
            .replace('${' + 'bildId' + '}', String(bildId));

        return this.getBild(path, aufgabeId, bildId, extraHttpRequestParams);
    }


    public getBild(path: string, aufgabeId: string, bildId: string, extraHttpRequestParams?: any): Observable<Response> {
        // const queryParameters = new URLSearchParams('', new CustomQueryEncoderHelper());
        // const headers = new Headers(this.defaultHeaders.toJSON()); // https://github.com/angular/angular/issues/6845
        //
        // // verify required parameter 'aufgabeId' is not null or undefined
        // if (aufgabeId === null || aufgabeId === undefined) {
        //     throw new Error('Required parameter aufgabeId was null or undefined when calling getAufgabeFehlerBild.');
        // }
        // // verify required parameter 'bildId' is not null or undefined
        // if (bildId === null || bildId === undefined) {
        //     throw new Error('Required parameter bildId was null or undefined when calling getAufgabeFehlerBild.');
        // }
        //
        // // to determine the Accept header
        // const produces: string[] = [];
        //
        // // authentication (oauth2) required
        // // oauth required<
        // if (this.configuration.accessToken) {
        //     const accessToken = typeof this.configuration.accessToken === 'function'
        //         ? this.configuration.accessToken()
        //         : this.configuration.accessToken;
        //     headers.set('Authorization', 'Bearer ' + accessToken);
        // }
        //
        //
        // let requestOptions: RequestOptionsArgs = new RequestOptions({
        //     method: RequestMethod.Get,
        //     headers,
        //     search: queryParameters,
        //     withCredentials: this.configuration.withCredentials
        // });
        // // https://github.com/swagger-api/swagger-codegen/issues/4037
        // if (extraHttpRequestParams) {
        //     requestOptions = (<any> Object).assign(requestOptions, extraHttpRequestParams);
        // }
        //
        // return this.httpClient.request<any>(path, requestOptions);


        if (aufgabeId === null || aufgabeId === undefined) {
            throw new Error('Required parameter aufgabeId was null or undefined when calling getAufgabeFehlerBild.');
        }
        if (bildId === null || bildId === undefined) {
            throw new Error('Required parameter bildId was null or undefined when calling getAufgabeFehlerBild.');
        }

        const headers = this.defaultHttpHeaders;

        // authentication (oauth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.get<any>(path,
            {
                headers,
                withCredentials: this.configuration.withCredentials,
            }
        );
    }
}
