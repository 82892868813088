import {CUSTOM_ELEMENTS_SCHEMA, Injectable, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SopiListComponent} from '../sopi-list/sopi-list.component';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {IonicModule} from '@ionic/angular';
import {SopiInfoboxComponent} from '../sopi-infobox/sopi-infobox.component';
import {SopiDockpanelComponent} from '../sopi-dockpanel/sopi-dockpanel.component';
import {SopiDockpanelBottomComponent} from '../sopi-dockpanel-bottom/sopi-dockpanel-bottom.component';
import {SopiDockpanelTopComponent} from '../sopi-dockpanel-top/sopi-dockpanel-top.component';
import {SopiDockpanelContentComponent} from '../sopi-dockpanel-content/sopi-dockpanel-content.component';
import {SopiFooterComponent} from '../sopi-footer/sopi-footer.component';
import {DayOfWeekPipe} from '../core/day-of-week.pipe';
import {SopiInnerItemComponent} from '../sopi-inner-item/sopi-inner-item.component';
import {PinboardPinComponent} from '../pinboard-pin/pinboard-pin.component';
import {SystemMeldungComponent} from '../system-meldung/system-meldung.component';
import {SopiNavButtonComponent} from '../sopi-nav-button/sopi-nav-button.component';


@Injectable()

@NgModule({
    declarations: [
        SopiListComponent,
        SopiInnerItemComponent,
        SopiInfoboxComponent,
        SopiDockpanelComponent,
        SopiDockpanelTopComponent,
        SopiDockpanelContentComponent,
        SopiDockpanelBottomComponent,
        SopiFooterComponent,
        SopiNavButtonComponent,
        DayOfWeekPipe,
        PinboardPinComponent,
        SystemMeldungComponent
    ],
    entryComponents: [],
    imports: [
        CommonModule,
        SopiScrollModule,
        IonicModule,
    ],
    exports: [
        CommonModule,
        SopiListComponent,
        SopiInnerItemComponent,
        SopiInfoboxComponent,
        SopiDockpanelComponent,
        SopiDockpanelTopComponent,
        SopiDockpanelContentComponent,
        SopiDockpanelBottomComponent,
        SopiFooterComponent,
        SopiNavButtonComponent,
        DayOfWeekPipe,
        PinboardPinComponent,
        SystemMeldungComponent
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SopiComponentsModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SopiComponentsModule,
            providers: [
            ]
        };
    }
}
