export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '0.13.1-alpha.0.19',
    name: 'orga-app-admin-client',
    versionDate: '2022-11-17T09:44:12.637Z',
    description: 'ORGA App Admin Client',
    gitCommitHash: '80e1aee',
    versionLong: '0.13.1-alpha.0.19-80e1aee',
};
export default versions;
