/**
 * orga app | admin
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Benutzer } from '../model/benutzer';
import { CacheClearInfo } from '../model/cacheClearInfo';
import { DashboardData } from '../model/dashboardData';
import { KeyValueItem } from '../model/keyValueItem';
import { MandantConfigRequest } from '../model/mandantConfigRequest';
import { MandantInfo } from '../model/mandantInfo';
import { UploadResponse } from '../model/uploadResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetAdminDashboard (Auth)
     * 
     * @param timeout_seconds 
     * @param werte 
     * @param letzte_x 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminDashboard(timeout_seconds?: number, werte?: string, letzte_x?: number, observe?: 'body', reportProgress?: boolean): Observable<DashboardData>;
    public getAdminDashboard(timeout_seconds?: number, werte?: string, letzte_x?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardData>>;
    public getAdminDashboard(timeout_seconds?: number, werte?: string, letzte_x?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardData>>;
    public getAdminDashboard(timeout_seconds?: number, werte?: string, letzte_x?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (timeout_seconds !== undefined && timeout_seconds !== null) {
            queryParameters = queryParameters.set('timeout_seconds', <any>timeout_seconds);
        }
        if (werte !== undefined && werte !== null) {
            queryParameters = queryParameters.set('werte', <any>werte);
        }
        if (letzte_x !== undefined && letzte_x !== null) {
            queryParameters = queryParameters.set('letzte_x', <any>letzte_x);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DashboardData>(`${this.basePath}/api/admin/dashboard`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetConfig (Auth)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfig(observe?: 'body', reportProgress?: boolean): Observable<Array<KeyValueItem>>;
    public getConfig(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<KeyValueItem>>>;
    public getConfig(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<KeyValueItem>>>;
    public getConfig(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<KeyValueItem>>(`${this.basePath}/api/admin/config`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantAdminListe (Auth)
     * 
     * @param mandant_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantAdminListe(mandant_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Benutzer>>;
    public getMandantAdminListe(mandant_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Benutzer>>>;
    public getMandantAdminListe(mandant_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Benutzer>>>;
    public getMandantAdminListe(mandant_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling getMandantAdminListe.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Benutzer>>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/benutzer/admins`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantBenutzerGesamtListe (Auth)
     * 
     * @param mandant_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantBenutzerGesamtListe(mandant_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Benutzer>>;
    public getMandantBenutzerGesamtListe(mandant_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Benutzer>>>;
    public getMandantBenutzerGesamtListe(mandant_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Benutzer>>>;
    public getMandantBenutzerGesamtListe(mandant_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling getMandantBenutzerGesamtListe.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Benutzer>>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/benutzer/gesamt`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantBenutzerListe (Auth)
     * 
     * @param mandant_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantBenutzerListe(mandant_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Benutzer>>;
    public getMandantBenutzerListe(mandant_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Benutzer>>>;
    public getMandantBenutzerListe(mandant_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Benutzer>>>;
    public getMandantBenutzerListe(mandant_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling getMandantBenutzerListe.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Benutzer>>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/benutzer/benutzer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantBenutzerListeRaw (Auth)
     * 
     * @param mandant_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantBenutzerListeRaw(mandant_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getMandantBenutzerListeRaw(mandant_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getMandantBenutzerListeRaw(mandant_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getMandantBenutzerListeRaw(mandant_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling getMandantBenutzerListeRaw.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/benutzer/benutzer/raw`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantConfig (Auth)
     * 
     * @param mandant_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantConfig(mandant_id: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: any; }>;
    public getMandantConfig(mandant_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: any; }>>;
    public getMandantConfig(mandant_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: any; }>>;
    public getMandantConfig(mandant_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling getMandantConfig.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: any; }>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/config`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantInfoBenutzerListe (Auth)
     * 
     * @param mandant_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantInfoBenutzerListe(mandant_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Benutzer>>;
    public getMandantInfoBenutzerListe(mandant_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Benutzer>>>;
    public getMandantInfoBenutzerListe(mandant_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Benutzer>>>;
    public getMandantInfoBenutzerListe(mandant_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling getMandantInfoBenutzerListe.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Benutzer>>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/benutzer/infobenutzer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantLogo (Auth)
     * 
     * @param mandant_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantLogo(mandant_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getMandantLogo(mandant_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getMandantLogo(mandant_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getMandantLogo(mandant_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling getMandantLogo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/logo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantTecAdminListe (Auth)
     * 
     * @param mandant_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantTecAdminListe(mandant_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Benutzer>>;
    public getMandantTecAdminListe(mandant_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Benutzer>>>;
    public getMandantTecAdminListe(mandant_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Benutzer>>>;
    public getMandantTecAdminListe(mandant_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling getMandantTecAdminListe.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Benutzer>>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/benutzer/tecadmins`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantenListe (Auth)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantenListe(observe?: 'body', reportProgress?: boolean): Observable<Array<MandantInfo>>;
    public getMandantenListe(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MandantInfo>>>;
    public getMandantenListe(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MandantInfo>>>;
    public getMandantenListe(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MandantInfo>>(`${this.basePath}/api/admin/mandanten/liste`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostClearCache (Auth)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postClearCache(observe?: 'body', reportProgress?: boolean): Observable<CacheClearInfo>;
    public postClearCache(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CacheClearInfo>>;
    public postClearCache(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CacheClearInfo>>;
    public postClearCache(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<CacheClearInfo>(`${this.basePath}/api/admin/clearcache`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMandantClearCache (Auth)
     * 
     * @param mandant_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMandantClearCache(mandant_id: string, observe?: 'body', reportProgress?: boolean): Observable<CacheClearInfo>;
    public postMandantClearCache(mandant_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CacheClearInfo>>;
    public postMandantClearCache(mandant_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CacheClearInfo>>;
    public postMandantClearCache(mandant_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling postMandantClearCache.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<CacheClearInfo>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/clearcache`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMandantConfigSpeichern (Auth)
     * 
     * @param mandant_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMandantConfigSpeichern(mandant_id: string, body?: MandantConfigRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postMandantConfigSpeichern(mandant_id: string, body?: MandantConfigRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postMandantConfigSpeichern(mandant_id: string, body?: MandantConfigRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postMandantConfigSpeichern(mandant_id: string, body?: MandantConfigRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling postMandantConfigSpeichern.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/config/speichern`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMandantSpeichern (Auth)
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMandantSpeichern(body?: MandantInfo, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public postMandantSpeichern(body?: MandantInfo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public postMandantSpeichern(body?: MandantInfo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public postMandantSpeichern(body?: MandantInfo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/api/admin/mandanten/speichern`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMandantUpload (Auth)
     * 
     * @param mandant_id 
     * @param file 
     * @param folder 
     * @param uploadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMandantUpload(mandant_id: string, file?: Blob, folder?: string, uploadId?: string, observe?: 'body', reportProgress?: boolean): Observable<UploadResponse>;
    public postMandantUpload(mandant_id: string, file?: Blob, folder?: string, uploadId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadResponse>>;
    public postMandantUpload(mandant_id: string, file?: Blob, folder?: string, uploadId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadResponse>>;
    public postMandantUpload(mandant_id: string, file?: Blob, folder?: string, uploadId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling postMandantUpload.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folder !== undefined && folder !== null) {
            queryParameters = queryParameters.set('folder', <any>folder);
        }
        if (uploadId !== undefined && uploadId !== null) {
            queryParameters = queryParameters.set('uploadId', <any>uploadId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<UploadResponse>(`${this.basePath}/api/admin/mandanten/${encodeURIComponent(String(mandant_id))}/upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostStop (Auth)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postStop(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public postStop(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public postStop(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public postStop(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<string>(`${this.basePath}/api/admin/stop`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostUpload (Auth)
     * 
     * @param file 
     * @param folder 
     * @param uploadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUpload(file?: Blob, folder?: string, uploadId?: string, observe?: 'body', reportProgress?: boolean): Observable<UploadResponse>;
    public postUpload(file?: Blob, folder?: string, uploadId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadResponse>>;
    public postUpload(file?: Blob, folder?: string, uploadId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadResponse>>;
    public postUpload(file?: Blob, folder?: string, uploadId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folder !== undefined && folder !== null) {
            queryParameters = queryParameters.set('folder', <any>folder);
        }
        if (uploadId !== undefined && uploadId !== null) {
            queryParameters = queryParameters.set('uploadId', <any>uploadId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<UploadResponse>(`${this.basePath}/api/admin/upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
