import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from './environment';


export const azureAdImplicitFlowV1: AuthConfig = {
    issuer: `https://sts.windows.net/${environment.azureAd.tenantId}/`,
    tokenEndpoint: `https://login.microsoftonline.com/${environment.azureAd.tenantId}/oauth2/token`,
    userinfoEndpoint: `https://login.microsoftonline.com/${environment.azureAd.tenantId}/openid/userinfo`,
    redirectUri: window.location.origin,
    clientId: environment.azureAd.clientId,
    scope: 'user_impersonation',
    showDebugInformation: environment.debug,
    resource: environment.azureAd.resource,

    strictDiscoveryDocumentValidation: false,
    
    // customQueryParams: {
    //     'prompt': 'select_account'
    // },

    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html'
};
