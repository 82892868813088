/**
 * orga app | datentransfer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DatenExportRequest } from '../model/datenExportRequest';
import { DatenImportRequest } from '../model/datenImportRequest';
import { DatentransferDaten } from '../model/datentransferDaten';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DatentransferService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * PostAdminExport (Auth)
     * 
     * @param mandant_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAdminExport(mandant_id: string, body?: DatenExportRequest, observe?: 'body', reportProgress?: boolean): Observable<DatentransferDaten>;
    public postAdminExport(mandant_id: string, body?: DatenExportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DatentransferDaten>>;
    public postAdminExport(mandant_id: string, body?: DatenExportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DatentransferDaten>>;
    public postAdminExport(mandant_id: string, body?: DatenExportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling postAdminExport.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DatentransferDaten>(`${this.basePath}/api/datentransfer/admin/export/${encodeURIComponent(String(mandant_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostAdminExportXlsx (Auth)
     * 
     * @param mandant_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAdminExportXlsx(mandant_id: string, body?: DatenExportRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postAdminExportXlsx(mandant_id: string, body?: DatenExportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postAdminExportXlsx(mandant_id: string, body?: DatenExportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postAdminExportXlsx(mandant_id: string, body?: DatenExportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling postAdminExportXlsx.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/datentransfer/admin/export/${encodeURIComponent(String(mandant_id))}/xlsx`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostAdminImport (Auth)
     * 
     * @param mandant_id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAdminImport(mandant_id: string, body?: DatenImportRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postAdminImport(mandant_id: string, body?: DatenImportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postAdminImport(mandant_id: string, body?: DatenImportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postAdminImport(mandant_id: string, body?: DatenImportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mandant_id === null || mandant_id === undefined) {
            throw new Error('Required parameter mandant_id was null or undefined when calling postAdminImport.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/datentransfer/admin/import/${encodeURIComponent(String(mandant_id))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostExport (Auth)
     * ORGA App Zugriff:  * Mandant: Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postExport(body?: DatenExportRequest, observe?: 'body', reportProgress?: boolean): Observable<DatentransferDaten>;
    public postExport(body?: DatenExportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DatentransferDaten>>;
    public postExport(body?: DatenExportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DatentransferDaten>>;
    public postExport(body?: DatenExportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DatentransferDaten>(`${this.basePath}/api/datentransfer/export`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostExportXlsx (Auth)
     * ORGA App Zugriff:  * Mandant: Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postExportXlsx(body?: DatenExportRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postExportXlsx(body?: DatenExportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postExportXlsx(body?: DatenExportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postExportXlsx(body?: DatenExportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/datentransfer/export/xlsx`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostImport (Auth)
     * ORGA App Zugriff:  * Mandant: Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postImport(body?: DatenImportRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postImport(body?: DatenImportRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postImport(body?: DatenImportRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postImport(body?: DatenImportRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/datentransfer/import`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
