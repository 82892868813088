import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { IsAuthenticated } from './guards/isAuthenticated';

import { NgxElectronModule } from 'ngx-electron';
import { AuthInterceptor } from './services/authInterceptor';
import { PushService } from './services/pushService';
import { DesktopIntegrationService } from './services/desktopIntegrationService';
import { PlatformService } from './services/platformService';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { AllowOriginInterceptor } from './services/allowOriginInterceptor';
import { MomentModule } from 'ngx-moment';
import 'moment/locale/de';
import { ApiModule as AdminApiModule } from 'src/clients/admin';
import { BASE_PATH as AdminBasePath } from '../clients/admin/variables';
import { ApiModule as ApiAdminModule } from 'src/clients/api';
import { BASE_PATH as ApiBasePath } from '../clients/api/variables';
import { ApiModule as DatentransferModule } from 'src/clients/datentransfer';
import { BASE_PATH as DatentransferBasePath } from '../clients/datentransfer/variables';
import { BASE_PATH as SopiBasePath } from '../sopi/variables';

import {SopiScrollModule} from '../sopi/components/sopi-scroll/sopi-scroll.module';
import {EffectsModule} from '@ngrx/effects';
import {ClipboardModule} from 'ngx-clipboard';
import {JsonEditorPageModule} from './mandanten/modale/json-editor.module';
import {DocumentService} from '../sopi/providers/document.serivce';
import {ImageService} from '../sopi/providers/image.service';
import {SopiComponentsModule} from './shared/sopi-components/sopi-components.module';
import {PipesModule} from '../sopi/pipes/pipes.module';

@NgModule({
  declarations: [AppComponent, LoginComponent],
  entryComponents: [LoginComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        NgProgressModule,
        NgProgressHttpModule,
        NgxElectronModule,
        ClipboardModule,
        MomentModule.forRoot(),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            }
        }),
        EffectsModule.forRoot([

        ]),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        FormsModule,
        AdminApiModule,
        ApiAdminModule,
        DatentransferModule,
        SopiScrollModule,
        JsonEditorPageModule.forRoot(),
        SopiComponentsModule.forRoot(),
        PipesModule,
    ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    OAuthService,
    PlatformService,
    PushService,
      DocumentService,
    DesktopIntegrationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AllowOriginInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {  provide: AdminBasePath, useValue: environment.appConfig.apiUrl },
    {  provide: ApiBasePath, useValue: environment.appConfig.apiUrl },
      {  provide: DatentransferBasePath, useValue: environment.appConfig.apiUrl },
      { provide: SopiBasePath, useValue: environment.appConfig.apiUrl },
    IsAuthenticated,
      ImageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
