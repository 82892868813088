import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';


@Component({
    selector: 'app-json-editor',
    templateUrl: './json-editor.page.html'
})
export class JsonEditorPage implements OnInit {
    @Input() untertitel: string;
    @Input() json: string;
    @Input() isEditable: boolean;

    public jsonObj: any;

    public editorOptions: JsonEditorOptions;
    @ViewChild(JsonEditorComponent, {static: true}) editor: JsonEditorComponent;

    private aiName = 'JsonEditorPage';

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController) {

        this.editorOptions = new JsonEditorOptions();
        this.editorOptions.modes = ['text', 'tree', 'view']; // set all allowed modes
        this.editorOptions.mode = 'tree'; // default mode
        /// this.editorOptions.expandAll = true;

    }

    ngOnInit() {
        this.jsonObj = JSON.parse(this.json);
    }

    abbrechen() {
        this.modalCtrl.dismiss();
    }

    async ok() {
        // Speichern geht noch nicht!!
        const data = {json: JSON.stringify(this.editor.get())};
        await this.modalCtrl.dismiss(data);
    }
}
