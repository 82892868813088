import {Inject, Injectable, Optional} from '@angular/core';
import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class DocumentService {

    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();
    protected basePath = 'https://bayer04-api-dev.azurewebsites.net';

    constructor(protected httpClient: HttpClient,
                @Optional() @Inject(BASE_PATH) basePath: string,
                @Optional() configuration: Configuration
    ) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.basePath = basePath || configuration.basePath || this.basePath;
        }

    }

    public getDokument(urlWithoutBaseUrl: string, extraHttpRequestParams?: any): Observable<Blob> {
        return this.httpClient
            .get(this.basePath + urlWithoutBaseUrl, {
                // headers: headers,
                responseType: 'blob'
            });
    }

    public getLocalDokument(urlWithoutBaseUrl: string, extraHttpRequestParams?: any): Observable<Blob> {
        return this.httpClient
            .get(urlWithoutBaseUrl, {
                // headers: headers,
                responseType: 'blob'
            });
    }

    public urltoFile(url, filename, mimeType) {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
        return (fetch(url)
                .then((res) => {
                    return res.arrayBuffer();
                })
                .then((buf) => {
                    return new File([buf], filename, {type: mimeType});
                })
        );
    }

    // tslint:disable-next-line:no-unnecessary-initializer
    public saveFile(data, filename, mimetype = undefined) {

        if (!data) {
            return;
        }

        const blob = data.constructor !== Blob
            ? new Blob([data], {type: mimetype || 'application/octet-stream'})
            : data;

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
            return;
        }

        // var lnk = document.createElement('a'),
        //     url = window.URL,
        //     objectURL;

        const lnk = document.createElement('a');
        const url = window.URL;
        let objectURL;

        if (mimetype) {
            lnk.type = mimetype;
        }

        lnk.download = filename || 'untitled';
        lnk.href = objectURL = url.createObjectURL(blob);
        lnk.dispatchEvent(new MouseEvent('click'));
        setTimeout(url.revokeObjectURL.bind(url, objectURL));
    }
}
