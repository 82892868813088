import {Component, OnInit} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {environment} from 'src/environments/environment';
import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {PushService} from './services/pushService';
import {DesktopIntegrationService} from './services/desktopIntegrationService';
import {azureAdImplicitFlowV1} from '../environments/auth.config';
import {filter} from 'rxjs/operators';
import {ClipboardService} from "ngx-clipboard";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;
    public appPages = [
        /*{
            title: 'Mandanten Liste',
            url: '/folder/Liste',
            icon: 'list'
        },
        {
            title: 'Mandant anlegen',
            url: '/folder/Anlegen',
            icon: 'add'
        },*/
        {
            title: 'Mandanten verwalten',
            url: '/mandanten',
            icon: 'list'
        },
        {
            title: 'Tools',
            url: '/tools',
            icon: 'warning'
        },
        {
            title: 'Dashboard',
            url: '/dashboard',
            icon: 'dollar'
        }

        // {
        //     title: 'Outbox',
        //     url: '/folder/Outbox',
        //     icon: 'paper-plane'
        // },
        // {
        //     title: 'Favorites',
        //     url: '/folder/Favorites',
        //     icon: 'heart'
        // },
        // {
        //     title: 'Archived',
        //     url: '/folder/Archived',
        //     icon: 'archive'
        // },
        // {
        //     title: 'Trash',
        //     url: '/folder/Trash',
        //     icon: 'trash'
        // },
        // {
        //     title: 'Spam',
        //     url: '/folder/Spam',
        //     icon: 'warning'
        // }
    ];

    public envi = environment;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private oauthService: OAuthService,
        private pushService: PushService,
        private desktopIntegration: DesktopIntegrationService,
        private clipboardService: ClipboardService
    ) {


        this.initOAuth();
        this.desktopIntegration.register();

        this.initializeApp();

    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
        const path = window.location.pathname.split('mandanten')[1];
        if (path !== undefined) {
            // console.log("pages: ", this.appPages);
            this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
        }
    }

    // Sample: Login method
    public login() {
        this.oauthService.initLoginFlow();
    }

    // Sample: Logout method
    public logout() {
        this.oauthService.logOut();
    }

    // Sample: Get the dispaly name claim
    public get name() {
        const claims = this.oauthService.getIdentityClaims();
        if (!claims) {
            return null;
        }
        return claims['name'];
    }

    public refreshToken() {

        if (this.oauthService.hasValidAccessToken) {
                    
            this.oauthService
                .silentRefresh(this.getRefreshParams(this.oauthService.getIdentityClaims()))
                .then(info => console.debug('refresh ok', info))
                .catch(err => console.error('refresh error', err));
        }
    }

    // Sample: Get the access token
    public get token() {
        return this.oauthService.getAccessToken();
    }

    // Sample: Get the access token expiration ticks (numeric)
    public get tokenExpiration() {
        return new Date(this.oauthService.getAccessTokenExpiration());
    }

    // Sample: Get the access token expiration date (in date format)
    public get tokenExpirationDate() {
        return this.oauthService.getAccessTokenExpiration();
    }

    private getRefreshParams(identityClaims: object) : object {
        let params: object = {}
            
        if (identityClaims !== null && identityClaims !== undefined) {
            
            console.info("getRefreshParams: identityClaims", identityClaims);
            let email = identityClaims["email"];
            console.info("getRefreshParams: identityClaims.email", email);
           
            if (email !== null  && email !== undefined) {
              params['login_hint'] = email;
            }  else {

                let unique_name = identityClaims["unique_name"];
                console.info("getRefreshParams: identityClaims.unique_name", unique_name);

                if (unique_name !== null  && unique_name !== undefined) {
                    params['login_hint'] = unique_name;
                }
            }
        }
        
        return params;
    }

    private initOAuth() {

        this.oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                console.error(event);
            } else {
                console.warn(event);
            }
        });

        this.oauthService.setStorage(localStorage);
        this.oauthService.loadDiscoveryDocument(environment.azureAd.discoveryDocumentV1)
        .then(x=> {
            console.log("oauthService.loadDiscoveryDocument", x.info);
        });

        this.oauthService.configure(azureAdImplicitFlowV1);
   
        if (this.oauthService.hasValidAccessToken()) {
            console.log("oauthService.hasValidAccessToken");
            this.oauthService.setupAutomaticSilentRefresh(this.getRefreshParams(this.oauthService.getIdentityClaims()));
        }

        this.oauthService.tryLogin().then( (x) => {
            
            console.log("oauthService: tryLogin: ", x);
            //  this.pushService.start();
        });

        this.oauthService.events
            .pipe(filter(e => e.type === 'token_received'))
            .subscribe(_ =>  {
                console.log("oauthService: token_received: ", _.type);

                this.oauthService.setupAutomaticSilentRefresh(this.getRefreshParams(this.oauthService.getIdentityClaims()));
                
                // this.oauthService.loadUserProfile().then(ui=> {
                //     console.info("user profile:" , ui);                   
                // })
            });
    }

    copyToken() {
        this.clipboardService.copyFromContent(this.token);
    }
}
