import {Component, HostBinding, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {ActivatedRoute, Router} from '@angular/router';
import {PushService} from '../services/pushService';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    @HostBinding('class.box')
    public loginCssClass = true;

    public username: string;
    public password: string;
    public error: string;
    public envi = environment;

    constructor(
        private oauthService: OAuthService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _pushService: PushService
    ) {
    }

    public submit(): void {
        // console.log('submit');
        this.oauthService
            .fetchTokenUsingPasswordFlowAndLoadUserProfile(
                this.username,
                this.password
            )
            .then(
                () => {
                    // console.log('then');
                    this._pushService.start();
                    this._router.navigate([
                        this._activatedRoute.snapshot.queryParams['redirectTo']
                    ]);
                },
                error => (this.error = error)
            );
    }

    ngOnInit() {
    }

    // Sample: Login method
    public login() {
        this.oauthService.initLoginFlow();
    }
}
