import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sopi-footer',
  templateUrl: './sopi-footer.component.html',
  styleUrls: ['./sopi-footer.component.scss'],
})
export class SopiFooterComponent implements OnInit {

  // DESIGN
  @Input() borderRadiusTopRight = false;
  @Input() borderRadiusTopLeft = false;
  @Input() borderRadiusBottomRight = false;
  @Input() borderRadiusBottomLeft = false;
  @Input() borderRadiusTop = false;
  @Input() borderRadiusBottom = false;

  constructor() { }

  ngOnInit() {}

}
